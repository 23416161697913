import { render, staticRenderFns } from "./trade-data-app.vue?vue&type=template&id=56522faf&scoped=true&"
import script from "./trade-data-app.vue?vue&type=script&lang=js&"
export * from "./trade-data-app.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56522faf",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VMain,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/application/development/trade-dsm-frontend_2022/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56522faf')) {
      api.createRecord('56522faf', component.options)
    } else {
      api.reload('56522faf', component.options)
    }
    module.hot.accept("./trade-data-app.vue?vue&type=template&id=56522faf&scoped=true&", function () {
      api.rerender('56522faf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/trade-data-app/trade-data-app.vue"
export default component.exports