var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { attrs: { bg: "", fluid: "" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", [
                _c("h1", [
                  _vm._v(
                    "Trade Data App - " +
                      _vm._s(_vm.$store.state.app.selected_application)
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }